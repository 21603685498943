<script setup lang="ts">
const googleButtonRef = ref()

const emits = defineEmits(['login'])

const googleSignInHandler = (response: any) => emits('login', response)

onMounted(() => {
  const { google } = window as any
  google.accounts.id.initialize({
    client_id:
      '54333527757-cjah00q9a6d6l3i1p0236li1lj69gv38.apps.googleusercontent.com',
    callback: googleSignInHandler
  })
  google.accounts.id.renderButton(googleButtonRef.value, {
    theme: 'outline',
    size: 'medium',
    locale: 'id',
    text: 'signin_with'
  })
})
</script>

<template>
  <div ref="googleButtonRef" />
</template>
